import React, { useState } from "react"
import ImageMeta from "../../components/ImageMeta"

import TheaterVideo from "@components/Theater/TheaterVideo"
import TextPhoto from "../../components/TextPhoto/TextPhoto"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import CV from "../../../static/CV.pdf"
import { publishedWork } from "../../cms/fields/publishedWork"
import Button from "@bit/azheng.joshua-tree.button/Button"

var classNames = require("classnames")

function Publications({ post }) {
  const col1 = []
  const col2 = []
  const worksCount = post.leftColumnCount
    ? post.leftColumnCount
    : post.publishedWorks.length / 2 + 1

  post.publishedWorks.forEach((item, i) => {
    if (i < worksCount) {
      col1.push(item)
    } else {
      col2.push(item)
    }
  })

  const [show, setShow] = useState(false)

  return (
    <div className="publications">
      <div className="columns is-vcentered">
        <div className="column is-4"></div>
        <div className="column doctor-text-only-rows publications-header">
          <h3>{post.heading}</h3>
          <p>{post.blurb}</p>
          <p>Visit his author profile on Scopus to read more.</p>
        </div>
        <div className="column is-4"></div>
      </div>

      {show ? (
        <div className="columns publications-columns">
          <div className="column is-4"></div>
          <div className="column">
            {col1.map((work, i) => (
              <PublishedWork work={work.publishedWork} key={i} />
            ))}
          </div>
          <div className="column is-1"></div>
          <div className="vl"></div>
          <div className="column is-1"></div>
          <div className="column ">
            {col2.map((work, i) => (
              <PublishedWork work={work.publishedWork} key={i} />
            ))}
          </div>
          <div className="column is-4"></div>
        </div>
      ) : (
        <div className="columns publications-hidden ">
          <div className="column is-4"></div>
          <div className="column  works-hidden">
            {col1.map((work, i) => (
              <PublishedWork work={work.publishedWork} key={i} />
            ))}
          </div>
          <div className="column is-1"></div>
          <div className="vl works-hidden"></div>
          <div className="column is-1"></div>
          <div className="column works-hidden">
            {col2.map((work, i) => (
              <PublishedWork work={work.publishedWork} key={i} />
            ))}
          </div>
          <div className="column is-4"></div>
        </div>
      )}
      <div className=" read-more-btn">
        <a className="standard-button" onClick={() => setShow(!show)}>
          {!show ? "Read More" : "Read Less"}
        </a>
      </div>
    </div>
  )
}

function PublishedWork(props) {
  return (
    <div className="published-work">
      <h4>{props.work.title}</h4>
      <p>
        {props.work.authors}{" "}
        {props.work.href && (
          <a href={props.work.href} target="_blank" rel="noreferrer">
            Read More
          </a>
        )}
      </p>
    </div>
  )
}

export default Publications
