import React from "react"
import ImageMeta from "../../components/ImageMeta"
import NuvoImage from "../../components/NuvoImage"
import Button from "@bit/azheng.joshua-tree.button"

import ABOMS from "../../images/logo-ABOMS.svg"
import SocialReviews from "../../components/SocialReviews/SocialReviews"
import Stars from "../../components/SocialReviews/Stars"
import ContentColumn from "./ContentColumn"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import TheaterVideo from "@components/Theater/TheaterVideo"

function BodySection(props) {
  // if (props.post.hasProfileImage) {
  //   return (
  //     <div className="columns is-desktop top-section doctor">
  //       <Button
  //         buttonText={props.language === "es" ? "ATRÁS" : "BACK"}
  //         goBack
  //         href={
  //           props.language === "es" ? "/es/acerca-de-nosotros/" : props.backUrl
  //         }
  //       />

  //       <div className="column is-1"></div>
  //       <div className="column doctor-image-wrapper is-11">
  //         <NuvoImage
  //           cloudName="nuvolum"
  //           publicId={props.post.cutoutImage}
  //           useAR
  //           width="auto"
  //         ></NuvoImage>
  //       </div>
  //       <div className="column is-1"></div>

  //       <ContentColumn
  //         heading={props.post.heading}
  //         topBlurb={props.post.topBlurb}
  //         badge={props.post.badge}
  //         language={props.language}
  //         doctor={props.doctor}
  //       />
  //       <div className="column"></div>
  //     </div>
  //   )
  // }
  return (
    <>
      <div className="columns has-text-centered top-section doctor-body doctor imageless is-vcentered">
        <div className="column is-3 is-hidden-tablet-only"></div>
        <div className="column is-2 is-hidden-desktop"></div>
        <div className="column">
          <div className="columns">
            <div className="column blurb-column">
              <MarkdownViewer markdown={props.post.aboutSection.body} />
              <div>
                {props.youtube && (
                  <TheaterVideo
                    videoUrl={`https://www.youtube.com/watch?v=${props.youtube}`}
                    controls={true}
                    playing={true}
                    onlyButton={true}
                    buttonClass="contained"
                  />
                )}
              </div>
            </div>
            <div className="column is-2"></div>

            <div className="column edu-column">
              <TextOnlySection
                text={props.post.educationResidency.textObject.body}
              />

              <TextOnlySection
                text={props.post.certifications.textObject.body}
              />

              <TextOnlySection
                text={props.post.hobbiesInterests.textObject.body}
              />

              {props.post.allBadges.hasBadges && (
                <div className="edu-column-badges-container">
                  {props.post.allBadges.images.map((img, i) => (
                    <ImageMeta
                      className="edu-column-badges-image"
                      key={i}
                      cloudName="nuvolum"
                      publicId={img}
                      responsive
                      width="auto"
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
          <div
            className={`is-hidden-desktop is-hidden-mobile rating-review ${
              !props.post.badge.hasBadge ? "no-badge" : ""
            }`}
            style={{ marginTop: 30 }}>
            {props.post.badge && props.post.badge.hasBadge && (
              <img className="badge" src={ABOMS} />
            )}
          </div>
        </div>
        <div className="column is-3 is-hidden-tablet-only"></div>
        <div className="column is-2 is-hidden-desktop"></div>
      </div>
    </>
  )
}

function TextOnlySection(props) {
  return (
    <div className="columns text-only-doc-info">
      <div className="column is-2"></div>
      <div className="column">
        <MarkdownViewer markdown={props.text} />
      </div>
      <div className="column is-2"></div>
    </div>
  )
}

export default BodySection
