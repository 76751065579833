import React from "react"
import PropTypes from "prop-types"
import ABOMS from "./logo-ABOMS.svg"
import SocialReviews from "../../components/SocialReviews/SocialReviews"
import { SocialIconLink } from "../../components/SocialReviews"
function ContentColumn({
  heading,
  topBlurb,
  language,
  badge,
  doctor,
  socialAccounts,
  shortName
}) {
  let docName = heading
  docName = addNewlineAfterString(docName, "Meet")

  return (
    <div className="column is-10 doctor-top-intro">
      <div
        style={{ whiteSpace: "pre-wrap" }}
        className="doctor-top-intro-inner">
        <h1>{docName}</h1>
        <p className="large-doctor-text large">{topBlurb}</p>
        <div className="rating-review">
          <SocialReviews
            socialData={doctor}
            google
            isCentered
            language={language}
          />
        </div>
        {socialAccounts && (
          <div className="doctor__social">
            <h3 className="has-text-centered">
              Follow {shortName} on Social Media!
            </h3>
            <div className="doctor__social-accounts">
              {socialAccounts.map(item => (
                <div key={item.link} className="doctor__social-account-item">
                  <SocialIconLink
                    platform={item.platform}
                    link={item.link}
                    iconColor="#fff"
                    bgColor="#2e50a2"
                    size={50}
                  />
                  <p>{item.platform}</p>
                </div>
              ))}
            </div>
          </div>
        )}
        {badge && badge.hasBadge && (
          <>
            <div className="column is-3"></div>
            <div className="column is-6">
              <img className="badge" src={ABOMS} />
            </div>
            <div className="column is-3"></div>
          </>
        )}
      </div>
    </div>
  )
}

ContentColumn.propTypes = {
  heading: PropTypes.string.isRequired,
  topBlurb: PropTypes.string.isRequired,
  language: PropTypes.string,
  badge: PropTypes.object,
  hasBadge: PropTypes.bool
}

export default ContentColumn

function addNewlineAfterString(/*in/out*/ text, /*in*/ str) {
  return text.replace(`${str} `, `${str}\n`)
}
